.Navbar {
  height: 120px;
  background-color: #fcfbeb;
  width: 100%;
  color: rgb(25, 68, 63);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 35px;
  position: relative;
}

.div {
  visibility: hidden;
}

.navbar-button {
  position: absolute;
  right: 50px;
}

.Logo {
  height: 250px;
  width: 250px;
  object-fit: contain;

  @media (max-width: 572px) {
    height: 200px;
    width: 200px;
  }
}

.extra-navbar {
  position: absolute;
  background-color: #fcfbeb;
  /* background-color: black; */
  top: 120px;
  height: calc(70vh - 130px);
  width: 100%;
  animation: slideDown 0.5s ease forwards;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 9px 10px rgba(0, 0, 0, 0.05);
}

@keyframes slideDown {
  from {
    opacity: 0.5;
    transform: translateY(-5%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

#checkbox {
  display: none;
}

.toggle {
  position: absolute;
  right: 50px;
  width: 60px;
  height: 60px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
  transition-duration: 0.5s;
  /* margin-top: 20px; */
  /* margin-left: -30px; */
  transform: scale(0.7);
  @media (max-width: 572px) {
    right: 10px;
  }
}

.bars {
  width: 100%;
  height: 6px;
  background-color: rgb(25, 68, 63);
  border-radius: 4px;
}

#bar2 {
  transition-duration: 0.8s;
}

#bar1,
#bar3 {
  width: 70%;
}

#checkbox:checked ~ .toggle .bars {
  position: absolute;
  transition-duration: 0.5s;
}

#checkbox:checked ~ .toggle #bar2 {
  transform: scaleX(0);
  transition-duration: 0.5s;
}

#checkbox:checked ~ .toggle #bar1 {
  width: 80%;
  transform: rotate(45deg);
  transition-duration: 0.5s;
}

#checkbox:checked ~ .toggle #bar3 {
  width: 80%;
  transform: rotate(-45deg);
  transition-duration: 0.5s;
}

#checkbox:checked ~ .toggle {
  transition-duration: 0.5s;
  transform: rotate(180deg);
}
