.Banner {
  background-color: #fcfbeb;
  width: 100%;
  color: rgb(25, 68, 63);
  height: calc(100vh - 120px);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: -5%;

  @media (max-width: 768px) {
    h1 {
      font-size: 24px;
    }

    h2 {
      font-size: 18px;
    }
  }

  @media (max-width: 355px) {
    h1 {
      font-size: 20px;
    }

    h2 {
      font-size: 16px;
    }
  }
}
